const directives = [
    {
        name: 'fen2yuan',
        func: {
            mounted(el: any, binding: any, vnode: any) {
                el.innerHTML += (binding.value / 100).toFixed(2);
            }
        }
    }
];

export default directives;