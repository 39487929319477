import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import components  from '@/components/components';
import directives from "@/components/directives";
import { setGlobalOptions } from 'vue-request';

setGlobalOptions({
    manual: true
});

const app = createApp(App);
components.forEach((j: {key: string, component: any}) => app.component(j.key, defineAsyncComponent(() => j.component)));
directives.forEach((j: {name: string, func: any}) => app.directive(j.name, j.func));
app.use(Antd);
app.use(store);
app.use(router);
app.mount('#app');