<template>
  <a-config-provider :locale="locale">
    <router-view/>
  </a-config-provider>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  export default defineComponent({
    setup() {
      return {
        locale: zhCN
      }
    }
  })
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
</style>
