
  import { defineComponent } from 'vue';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  export default defineComponent({
    setup() {
      return {
        locale: zhCN
      }
    }
  })
