
const comps = [
    {
        key: 'el-table',
        component: import('@/components/el-table.vue')
    },
    {
        key: 'el-search',
        component: import('@/components/el-search.vue')
    },
    {
        key: 'el-company-list',
        component: import('@/components/el-company-list.vue')
    }
];

const pages = [
    {
        key: 'Home',
        component: import('@/views/Home.vue')
    },
    {
        key: 'Employee',
        component: import('@/views/Employee.vue')
    },
    {
        key: 'Company',
        component: import('@/views/Company.vue')
    },
    {
        key: 'wage',
        component: import('@/views/Wage.vue')
    },
    {
        key: 'account',
        component: import('@/views/Account.vue')
    },
    {
        key: 'consult',
        component: import('@/views/Consult.vue')
    },
    {
        key: 'dict',
        component: import('@/views/Dict.vue')
    }
];

const components = [
    ...comps,
    ...pages
];

export default components;